import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Header, Segment } from "semantic-ui-react"

import Layout from "../components/Layout"

const createGallery = images => {
  return images.map((image, i) => {
    return <Img fluid={image.childImageSharp.fluid} key={i} />
  })
}

export const ProjectPageTemplate = ({
  image,
  title,
  heading,
  description,
  gallery,
}) => (
  <Container>
    <Img
      fluid={image.childImageSharp.fluid}
      style={{ width: "50%", margin: "auto" }}
    />
    <Header>{title}</Header>
    <Segment>
      {heading}
      <br />
      {description}
    </Segment>
    {createGallery(gallery)}
  </Container>
)

ProjectPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
}

const ProjectPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ProjectPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        gallery={frontmatter.galleryImages}
      />
    </Layout>
  )
}

ProjectPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProjectPage

export const projectPageQuery = graphql`
  query ProjectPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          ...fluidImage
        }
        heading
        description
        galleryImages {
          ...fluidImage
        }
      }
    }
  }
`
